<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.4659 4.84C16.1591 4.33 15.6479 4 15.077 4L5.70415 4.01C4.76687 4.01 4 4.9 4 6V16C4 17.1 4.76687 17.99 5.70415 17.99L15.077 18C15.6479 18 16.1591 17.67 16.4659 17.16L19.8401 11.58C19.944 11.4112 20 11.2083 20 11C20 10.7917 19.944 10.5888 19.8401 10.42L16.4659 4.84Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '22'
    }
  }
}
</script>
