<template>
  <svg
    viewBox="0 0 24 24"
    :height="`${height}px` || '24px'"
    :width="`${width}px` || '24px'"
    :class="`fill-current stroke-current text-${color}`"
  >
    <path
      d="M13.5578 12L13.5744 11.9834L18.0275 7.5303L18.0275 7.53029C18.2344 7.32371 18.3509 7.04338 18.3511 6.75097C18.3514 6.45857 18.2355 6.17803 18.0289 5.97109C17.8223 5.76414 17.542 5.64774 17.2496 5.64748C16.9572 5.64722 16.6766 5.76313 16.4697 5.9697L13.5578 12ZM13.5578 12L13.5744 12.0166L18.0303 16.4697C18.2372 16.6766 18.3535 16.9573 18.3535 17.25C18.3535 17.5427 18.2372 17.8234 18.0303 18.0303C17.8234 18.2372 17.5427 18.3535 17.25 18.3535C16.9573 18.3535 16.6766 18.2372 16.4697 18.0303L12.0166 13.5772L12 13.5606L11.9834 13.5772L7.5303 18.0303C7.32335 18.2372 7.04267 18.3535 6.75 18.3535C6.45733 18.3535 6.17665 18.2372 5.9697 18.0303C5.76275 17.8234 5.64648 17.5427 5.64648 17.25C5.64648 16.9573 5.76275 16.6766 5.9697 16.4697L10.4228 12.0166L10.4394 12L10.4228 11.9834L5.9697 7.5303C5.76275 7.32335 5.64648 7.04267 5.64648 6.75C5.64648 6.45733 5.76275 6.17665 5.9697 5.9697C6.17665 5.76275 6.45733 5.64648 6.75 5.64648C7.04267 5.64648 7.32335 5.76275 7.5303 5.9697L11.9834 10.4228L12 10.4394L12.0166 10.4228L16.4697 5.96971L13.5578 12Z"
      stroke-width="0.046875"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#fff",
    },
    width: {
      type: String,
      default: "24",
    },
    height: {
      type: String,
      default: "24",
    },
  },
};
</script>
