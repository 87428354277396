<template>
  <div>
    <svg
      :height="`${height}px` || '20px'"
      :width="`${width}px` || '12px'"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.12871 7V13.743C4.1365 14.236 4.33784 14.7063 4.68928 15.0522C5.04073 15.3982 5.51409 15.592 6.00723 15.592C6.50036 15.592 6.97373 15.3982 7.32517 15.0522C7.67661 14.7063 7.87795 14.236 7.88574 13.743L7.89183 4.90469C7.89695 4.48325 7.81836 4.06499 7.66062 3.67415C7.50288 3.28331 7.26913 2.92767 6.97292 2.62784C6.6767 2.32801 6.32392 2.08997 5.93503 1.9275C5.54613 1.76503 5.12885 1.68137 4.70738 1.68137C4.28591 1.68137 3.86863 1.76503 3.47974 1.9275C3.09084 2.08997 2.73806 2.32801 2.44185 2.62784C2.14564 2.92767 1.91189 3.28331 1.75415 3.67415C1.59641 4.06499 1.51782 4.48325 1.52293 4.90469V13.8025C1.51434 14.3958 1.62379 14.9849 1.8449 15.5356C2.06602 16.0862 2.39439 16.5874 2.81093 17.01C3.22747 17.4326 3.72386 17.7682 4.27125 17.9973C4.81864 18.2264 5.40611 18.3443 5.99949 18.3443C6.59288 18.3443 7.18034 18.2264 7.72773 17.9973C8.27512 17.7682 8.77151 17.4326 9.18805 17.01C9.60459 16.5874 9.93296 16.0862 10.1541 15.5356C10.3752 14.9849 10.4846 14.3958 10.4761 13.8025V5.48782"
        :stroke="`${color}`"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#3b82f6",
    },
    width: {
      type: String,
      default: "12",
    },
    height: {
      type: String,
      default: "20",
    },
  },
};
</script>
<style lang="scss" scoped></style>
