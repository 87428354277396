var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "height": "".concat(_vm.height, "px"),
      "width": "".concat(_vm.width, "px"),
      "viewBox": "0 0 25 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M12.5 14.25C13.7426 14.25 14.75 13.2426 14.75 12C14.75 10.7574 13.7426 9.75 12.5 9.75C11.2574 9.75 10.25 10.7574 10.25 12C10.25 13.2426 11.2574 14.25 12.5 14.25Z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "d": "M12.5 21.75C13.7426 21.75 14.75 20.7426 14.75 19.5C14.75 18.2574 13.7426 17.25 12.5 17.25C11.2574 17.25 10.25 18.2574 10.25 19.5C10.25 20.7426 11.2574 21.75 12.5 21.75Z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "d": "M12.5 6.75C13.7426 6.75 14.75 5.74264 14.75 4.5C14.75 3.25736 13.7426 2.25 12.5 2.25C11.2574 2.25 10.25 3.25736 10.25 4.5C10.25 5.74264 11.2574 6.75 12.5 6.75Z",
      "fill": _vm.color
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }