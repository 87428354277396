<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.0679 20.9425L14.4161 1.16125C13.6611 -0.24125 11.6498 -0.24125 10.8942 1.16125L0.242925 20.9425C0.0789952 21.247 -0.00319152 21.5887 0.00438668 21.9345C0.0119649 22.2802 0.109049 22.618 0.286163 22.915C0.463277 23.212 0.71437 23.458 1.01493 23.629C1.31549 23.8 1.65525 23.8901 2.00105 23.8906H23.3067C23.6527 23.8907 23.9929 23.8009 24.2939 23.6302C24.5949 23.4594 24.8464 23.2134 25.0239 22.9163C25.2014 22.6193 25.2987 22.2812 25.3064 21.9352C25.3142 21.5893 25.232 21.2472 25.0679 20.9425V20.9425ZM12.6554 20.8281C12.4082 20.8281 12.1665 20.7548 11.961 20.6175C11.7554 20.4801 11.5952 20.2849 11.5006 20.0565C11.406 19.8281 11.3812 19.5767 11.4294 19.3343C11.4777 19.0918 11.5967 18.8691 11.7715 18.6942C11.9464 18.5194 12.1691 18.4004 12.4116 18.3521C12.654 18.3039 12.9054 18.3287 13.1338 18.4233C13.3622 18.5179 13.5574 18.6781 13.6948 18.8837C13.8321 19.0892 13.9054 19.3309 13.9054 19.5781C13.9054 19.9096 13.7737 20.2276 13.5393 20.462C13.3049 20.6964 12.9869 20.8281 12.6554 20.8281V20.8281ZM14.0129 8.25625L13.6542 15.8813C13.6542 16.1465 13.5488 16.4008 13.3613 16.5884C13.1737 16.7759 12.9194 16.8813 12.6542 16.8813C12.389 16.8813 12.1346 16.7759 11.9471 16.5884C11.7595 16.4008 11.6542 16.1465 11.6542 15.8813L11.2954 8.25937C11.2874 8.07724 11.3161 7.89535 11.3798 7.72454C11.4435 7.55374 11.541 7.39751 11.6664 7.26517C11.7918 7.13284 11.9425 7.0271 12.1097 6.95427C12.2768 6.88144 12.4569 6.843 12.6392 6.84125H12.6523C12.8358 6.84116 13.0175 6.87825 13.1863 6.95029C13.3551 7.02233 13.5076 7.12783 13.6345 7.26042C13.7614 7.39301 13.8602 7.54993 13.9248 7.72173C13.9894 7.89353 14.0185 8.07664 14.0104 8.26L14.0129 8.25625Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '26'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
