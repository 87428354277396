var render = function () {
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: "arrow ".concat(_vm.direction),
    on: {
      "click": function click() {
        _this.$emit('handleClick');
      }
    }
  }, [_c('svg', {
    attrs: {
      "version": "1.1",
      "id": "Capa_1",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 256 256",
      "height": "".concat(_vm.height, "px") || '20px',
      "width": "".concat(_vm.width, "px") || '20px'
    }
  }, [_c('g', [_c('g', [_c('polygon', {
    attrs: {
      "fill": _vm.color,
      "points": "79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128"
    }
  })])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }