<template>
  <svg :height="`${height}px`" :width="`${width}px`" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 0C5.83187 0 0 5.83187 0 13C0 20.1681 5.83187 26 13 26C20.1681 26 26 20.1681 26 13C26 5.83187 20.1681 0 13 0ZM13 19.9944C12.7528 19.9944 12.5111 19.9211 12.3055 19.7837C12.1 19.6464 11.9398 19.4511 11.8452 19.2227C11.7505 18.9943 11.7258 18.743 11.774 18.5005C11.8222 18.258 11.9413 18.0353 12.1161 17.8605C12.2909 17.6857 12.5137 17.5666 12.7561 17.5184C12.9986 17.4702 13.2499 17.4949 13.4784 17.5895C13.7068 17.6841 13.902 17.8444 14.0393 18.0499C14.1767 18.2555 14.25 18.4971 14.25 18.7444C14.25 19.0759 14.1183 19.3938 13.8839 19.6283C13.6495 19.8627 13.3315 19.9944 13 19.9944ZM14.3575 7.4225L13.9988 15.0475C13.9988 15.3127 13.8934 15.5671 13.7059 15.7546C13.5183 15.9421 13.264 16.0475 12.9987 16.0475C12.7335 16.0475 12.4792 15.9421 12.2916 15.7546C12.1041 15.5671 11.9987 15.3127 11.9987 15.0475L11.64 7.42625V7.42313C11.6321 7.23995 11.6614 7.05709 11.7261 6.88553C11.7908 6.71398 11.8895 6.55729 12.0163 6.4249C12.1432 6.29251 12.2955 6.18716 12.4641 6.11518C12.6327 6.04321 12.8142 6.00611 12.9975 6.00611C13.1808 6.00611 13.3623 6.04321 13.5309 6.11518C13.6995 6.18716 13.8518 6.29251 13.9787 6.4249C14.1055 6.55729 14.2042 6.71398 14.2689 6.88553C14.3336 7.05709 14.3629 7.23995 14.355 7.42313L14.3575 7.4225Z"
      fill="#EB5757"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#EB5757'
    },
    width: {
      type: String,
      default: '48'
    },
    height: {
      type: String,
      default: '48'
    }
  }
}
</script>
