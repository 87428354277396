<template>
  <svg
    :height="`${height}px`"
    :width="`${width}px`"
    :class="`fill-current  text-${color}`"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 7.75H6.16667V18.8C6.16667 19.2509 6.34226 19.6833 6.65482 20.0021C6.96738 20.3209 7.39131 20.5 7.83333 20.5H16.1667C16.6087 20.5 17.0326 20.3209 17.3452 20.0021C17.6577 19.6833 17.8333 19.2509 17.8333 18.8V7.75H7ZM15.8483 5.2L14.5 3.5H9.5L8.15167 5.2H4.5V6.9H19.5V5.2H15.8483Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#333",
    },
    width: {
      type: String,
      default: "24",
    },
    height: {
      type: String,
      default: "24",
    },
  },
};
</script>
